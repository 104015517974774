export default class VouchersModal {
    constructor() {
        this.dialog = document.querySelector('#c-vouchers-modal');
        this.closeButton = document.querySelector('.c-vouchers-modal__close');
        this.vouchersLink = document.querySelectorAll('a[href="#vouchersmodal"]');

        this.initEventListeners();
    }

    initEventListeners() {
        this.vouchersLink.forEach(link => {
            link.addEventListener("click", () => this.showDialog());
        });
        this.closeButton.addEventListener("click", () => this.closeDialog());
    }

    showDialog() {
        this.dialog.showModal();
    }
    
    closeDialog() {
        this.dialog.close();
    }
}