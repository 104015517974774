import Form from './modules/Form'
//import Splash from './modules/Splash'
import Parallax from './modules/Parallax'
// import BGParallax from './modules/BGParallax';
import Offcanvas from './modules/Offcanvas'
import BookButton from './modules/BookButton'
import BookingOption from './modules/BookingOption'
import PageTransition from './modules/PageTransition'
import MediaHeroSlider from './modules/MediaHeroSlider'
import ContentPostFilter from './modules/ContentPostFilter'
import WineList from './modules/WineList'
import ScrollDirection from './modules/ScrollDirection'
import GridFilter from './modules/GridFilter'
import TransferTool from './modules/TransferTool';
import VouchersModal from './modules/VouchersModal';

new Form()
// new Splash()
new WineList()
new Offcanvas()
new BookButton()
// new BGParallax()
new TransferTool()
new BookingOption()
new PageTransition()
new ScrollDirection()
// new MediaHeroSlider()
new ContentPostFilter()

// new Parallax([
//     '.c-media--layout-content-left .c-media__image',
//     '.c-media--layout-content-right .c-media__image',
//     '.c-media-group--offset-layout .c-media-group__item:nth-child(2)',
//     '.c-media-group--room-overview .c-media-group__item:last-of-type'
// ])

$(() => {
    new VouchersModal()
    
    $('.c-slider--media-hero').each(function() {
        new MediaHeroSlider(this)
    })

    $('.c-grid-filter').each(function() {
        new GridFilter(this)
    })
    

    // will need to look at replacement - changed how the image is rendered and it doesn't work with bg images
    // $('.c-cta--userflow').each(function() {
    //     new BGParallax(this)
    // })

    new Parallax([
        '.c-media--layout-content-left .c-media__image',
        '.c-media--layout-content-right .c-media__image',
        '.c-media-group--offset-layout .c-media-group__item:nth-child(2)',
        '.c-media-group--room-overview .c-media-group__item:last-of-type'
    ])

    // image gallery --grid variation
    $('.c-image-gallery--grid').each( function() {
        $(this).parents('.c-grid-filter--images').addClass('grid-layout')

        $(this).siblings('.c-grid-filter__filter').find('.c-grid-filter__radio-button').wrapAll('<div class="c-grid-filter__options"></div>')

        // trigger window resize for isotope grid to correctly adjust image
        window.dispatchEvent(new Event('resize'));
    })  
})
